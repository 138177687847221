import axios from "axios"

const Payments = ({payments}) => {

    const handlePayment = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/payment/create-checkout-session/${id}`)
            window.location = response.data.url
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            {payments && payments.map((p) => (
                <tr key={p._id}>
                    <td>
                        <div className="media align-items-center">
                            <img src="images/LOGO.png" alt="icon" className="wid-40 rounded" />
                            <div className="media-body ms-3">
                                <h5 className="mb-1">{p.name}</h5>
                            </div>
                        </div>
                    </td>
                    <td className="text-end">
                        <h5 className="mb-0">${p.priceInCents / 100}</h5>
                    </td>
                    <td className="text-end">
                        <button className="btn btn-primary" onClick={() => handlePayment(p._id)}>Checkout</button>
                    </td>
                </tr>
            ))}
        </>
    )
}

export default Payments