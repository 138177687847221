import { useState } from "react"
import { useAuth } from "../../../contexts/AuthContext"
import { useNavigate } from "react-router-dom";
import axios from "axios"


const SignIn = ({ message, setMessage }) => {
    const { login } = useAuth(); // Import the login function from your AuthContext
    const navigate = useNavigate(); // Get the navigate function
    const [formData,setFormData] = useState({ email: "", password: "" })
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // const response = await axios.post(`${}`, formData);
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/auth/login`, formData);
            if (response.status === 200){
                const userData = response.data;
                if (userData.token) {
                    login(userData);
                    navigate("/dashboard");
                }
            } else {
                setMessage({ display: true, error: true, text: "Incorrect Username or Password..." });
                setTimeout(() => {
                  setMessage( (prevMessage) => ({ ...prevMessage, display: false }));
                }, 5000);
            }
        } catch (error) {
            setMessage({ display: true, error: true, text: "Incorrect Username or Password..." });
            setTimeout(() => {
              setMessage( (prevMessage) => ({ ...prevMessage, display: false }));
            }, 5000);
        }
    
        e.target.reset();
    }

    return (
        <div className="auth-main">
        <div className="auth-wrapper v1">
          {/* <div > */}
            <form className="auth-form" onSubmit={handleSubmit}>
            <div className="card my-5">
              <div className="card-body">
                <div className="text-center">
                  <a href="/"><img src="images/LOGO.png" alt="img" style={{width: "60px", marginBottom: "1em"}}/></a>
                </div>
                <h4 className="text-center f-w-500 mb-3">Login with your email</h4>
                <div className="form-group mb-3">
                  <input type="email" className="form-control" id="floatingInput" placeholder="Email Address" onChange={(e) => setFormData({...formData, email: e.target.value})}/>
                </div>
                <div className="form-group mb-3">
                  <input type="password" className="form-control" id="floatingInput1" placeholder="Password" onChange={(e) => setFormData({...formData, password: e.target.value})} />
                </div>
                <div className="d-flex mt-1 justify-content-between align-items-center">
                  <h6 className="text-secondary f-w-400 mb-0">Forgot Password?</h6>
                </div>
                <div className="d-grid mt-4">
                  <button type="submit" className="btn btn-primary">Login</button>
                </div>
                <div className="d-flex justify-content-between align-items-end mt-4">
                  <h6 className="f-w-500 mb-0">Don't have an Account?</h6>
                  <a href="/register" className="link-primary">Create Account</a>
                </div>
              </div>
            </div>
            </form>
          {/* </div> */}
        </div>
      </div>
    )
}

export default SignIn