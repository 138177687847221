import { navItemsData } from "../../lib/tempData"
import NavItem from "./NavItem"
import NavUser from "./NavUser"
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";

const SideMenu = () => {
    const [user,setUser] = useState({})
    useEffect(() => {
        let user = jwtDecode(localStorage?.token);
        setUser(user)
    },[])
    return (
        <nav className="pc-sidebar">

            <div className="navbar-wrapper">

                <div className="m-header">
                    <a href="#" className="b-brand text-primary">
                        {/* ========   Change your logo from here   ============ */}
                        <img src="/images/LOGO.png" alt="logo" style={{width: "60px", margin: "0.75em"}} />
                        <span className="badge bg-light-success rounded-pill ms-2 theme-version">v24.1</span>
                    </a>
                </div>

                <div className="navbar-content">

                    <NavUser user={user} />

                    <ul className="pc-navbar">
                        {navItemsData.map((item, index) => (
                            <NavItem key={index} {...item} />
                        ))}
                    </ul>

                </div>

            </div>
        </nav>
    )
}

export default SideMenu