import { useEffect, useState } from "react";
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import SideMenu from "../SideMenu/SideMenu"
import Content from "./Content";

const Dashboard = () => {
    
    const handleSideBar = () => {
        let side = document.querySelector(".pc-sidebar")
        side.classList.remove("mob-sidebar-active")
    }
    
    return (
        <>
            {/* Pre-loader */}
            <div className="loader-bg">
                <div className="loader-track">
                    <div className="loader-fill"></div>
                </div>
            </div>
            <SideMenu />
            <Header/>
            {/* [ Main Content ] start */}
            <div className="pc-container" onClick={handleSideBar}>
                <Content/>
            </div>
            {/* [ Main Content ] end */}
            <Footer />
        </>
    )
}

export default Dashboard