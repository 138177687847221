import Payments from "./Payments"
import axios from "axios"
import { useEffect, useState } from "react"
const Content = () => {
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    // Create a cancellation token source for axios
    const cancelTokenSource = axios.CancelToken.source();

    async function fetchPayments() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/payment/user-payments/${localStorage.getItem("id")}`, {
          cancelToken: cancelTokenSource.token, // Set the cancel token
        });

        setPayments(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled (component unmounted), ignore errors
          return;
        }

        console.error('Error fetching payments:', error);
        setPayments([]); // Set payments to an empty array on error
      }
    }

    fetchPayments();

    // Cleanup function
    return () => {
      // Cancel the axios request when the component is unmounted
      cancelTokenSource.cancel('Component is unmounted');
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

    const handleSideBar = () => {
        let side = document.querySelector(".pc-sidebar")
        side.classList.remove("mob-sidebar-active")
    }
    
    return (
      <div className="pc-container" onClick={handleSideBar}>
        <div className="pc-content">
          <div className="tab-pane show active" id="ecomtab-1" role="tabpanel" aria-labelledby="ecomtab-tab-1">
            <div className="row">
              <div className="col-xl-8">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center my-2">
                      <div className="col-lg-6">
                        <p className="mb-0 h6">Your Payments</p>
                      </div>
                    </div>
                  </div>
                  <div className="card-body border-bottom">
                    <h5>Cart Item <span className="ms-2 f-14 px-2 badge bg-light-secondary rounded-pill">{payments.length}</span></h5>
                  </div>
                  <div className="card-body p-0 table-body">
                    <div className="table-responsive">
                      <table className="table mb-0" id="pc-dt-simple">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th className="text-end">Price</th>
                            <th className="text-end"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <Payments payments={payments} />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4"></div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Content