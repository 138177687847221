const NavItem = ({ iconId, text, link, submenuItems }) => {
    return (
        <li className={`pc-item ${submenuItems ? 'pc-hasmenu' : ''}`}>
            <a href={link || '#!'} className="pc-link">
                <span className="pc-micon">
                    <svg className="pc-icon">
                        <use href={`#${iconId}`}></use>
                    </svg>
                </span>
                <span className="pc-mtext">{text}</span>
                {submenuItems && <span className="pc-arrow"><i data-feather="chevron-right"></i></span>}
            </a>
            {submenuItems && (
                <ul className="pc-submenu">
                    {submenuItems.map((submenuItem, index) => (
                        <li key={index} className="pc-item">
                            <a className="pc-link" href={submenuItem.link}>
                                {submenuItem.text}
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    )
};

export default NavItem