import Footer from "../Footer/Footer"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import Content from "./Content"

const Projects = () => {
    
    return (
        <>
            {/* Pre-loader */}
            <div className="loader-bg">
                <div className="loader-track">
                    <div className="loader-fill"></div>
                </div>
            </div>
            <SideMenu/>
            <Header/>
            <Content/>
            <Footer/>
        </>
    )
}

export default Projects