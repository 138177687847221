import { useAuth } from "../../contexts/AuthContext"

const Header = () => {
    const { logout } = useAuth(); // Import the login function from your AuthContex
    const handleLogout = () => {
        logout()
    }
    const handleSideBar = () => {
        let side = document.querySelector(".pc-sidebar")
        if (side.classList.contains('pc-sidebar-hide')) {
            side.classList.remove('pc-sidebar-hide')
            
        } else {
            side.classList.add('pc-sidebar-hide')
        }
        if (side.classList.contains("mob-sidebar-active")){
            side.classList.remove("mob-sidebar-active")
        } else {
            side.classList.add("mob-sidebar-active")
        }
    }

    return (
        <header className="pc-header">
            <div className="header-wrapper"> {/* [Mobile Media Block] start */}
            <div className="me-auto pc-mob-drp">
            <ul className="list-unstyled">
                {/* ======= Menu collapse Icon ===== */}
                <li className="pc-h-item pc-sidebar-collapse">
                    <a href="#" className="pc-head-link ms-0" id="sidebar-hide" onClick={handleSideBar}>
                        <i className="ti ti-menu-2"></i>
                    </a>
                </li>
                <li className="pc-h-item pc-sidebar-popup">
                <a href="#" className="pc-head-link ms-0" id="mobile-collapse" onClick={handleSideBar}>
                    <i className="ti ti-menu-2"></i>
                </a>
                </li>
                <li className="dropdown pc-h-item">
                <a
                    className="pc-head-link dropdown-toggle arrow-none m-0 trig-drp-search"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="false"
                    aria-expanded="false"
                >
                    <svg className="pc-icon">
                    <use href="#custom-search-normal-1"></use>
                    </svg>
                </a>
                <div className="dropdown-menu pc-h-dropdown drp-search">
                    <form className="px-3 py-2">
                    <input type="search" className="form-control border-0 shadow-none" placeholder="Search here. . ." />
                    </form>
                </div>
                </li>
            </ul>
            </div>
            {/* [Mobile Media Block end] */}
            <div className="ms-auto">
            <ul className="list-unstyled">
                <li className="dropdown pc-h-item">
                <a
                    className="pc-head-link dropdown-toggle arrow-none me-0"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="false"
                    aria-expanded="false"
                >
                    <svg className="pc-icon">
                    <use href="#custom-setting-2"></use>
                    </svg>
                </a>
                <div className="dropdown-menu dropdown-menu-end pc-h-dropdown">
                    <a href="#!" className="dropdown-item">
                    <i className="ti ti-user"></i>
                    <span>My Account</span>
                    </a>
                    <a href="#!" className="dropdown-item">
                    <i className="ti ti-settings"></i>
                    <span>Settings</span>
                    </a>
                    <a href="/contact" className="dropdown-item">
                    <i className="ti ti-headset"></i>
                    <span>Support</span>
                    </a>
                    <button className="dropdown-item" onClick={handleLogout}>
                    <i className="ti ti-power"></i>
                    <span>Logout</span>
                    </button>
                </div>
                </li>
                {/* <li className="dropdown pc-h-item">
                <a
                    className="pc-head-link dropdown-toggle arrow-none me-0"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="false"
                    aria-expanded="false"
                >
                    <svg className="pc-icon">
                    <use href="#custom-notification"></use>
                    </svg>
                    <span className="badge bg-success pc-h-badge">3</span>
                </a>
                <div className="dropdown-menu dropdown-notification dropdown-menu-end pc-h-dropdown">
                    <div className="dropdown-header d-flex align-items-center justify-content-between">
                    <h5 className="m-0">Notifications</h5>
                    <a href="#!" className="btn btn-link btn-sm">Mark all read</a>
                    </div>
                    <div className="dropdown-body text-wrap header-notification-scroll position-relative" style={{maxHeight: "calc(100vh - 215px)"}}>
                    <p className="text-span">Today</p>
                    <div className="card mb-2">
                        <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                            <svg className="pc-icon text-primary">
                                <use href="#custom-layer"></use>
                            </svg>
                            </div>
                            <div className="flex-grow-1 ms-3">
                            <span className="float-end text-sm text-muted">2 min ago</span>
                            <h5 className="text-body mb-2">UI/UX Design</h5>
                            <p className="mb-0"
                                >Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                type and scrambled it to make a type</p
                            >
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                            <svg className="pc-icon text-primary">
                                <use href="#custom-sms"></use>
                            </svg>
                            </div>
                            <div className="flex-grow-1 ms-3">
                            <span className="float-end text-sm text-muted">1 hour ago</span>
                            <h5 className="text-body mb-2">Message</h5>
                            <p className="mb-0">Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <p className="text-span">Yesterday</p>
                    <div className="card mb-2">
                        <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                            <svg className="pc-icon text-primary">
                                <use href="#custom-document-text"></use>
                            </svg>
                            </div>
                            <div className="flex-grow-1 ms-3">
                            <span className="float-end text-sm text-muted">2 hour ago</span>
                            <h5 className="text-body mb-2">Forms</h5>
                            <p className="mb-0"
                                >Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                type and scrambled it to make a type</p
                            >
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                            <svg className="pc-icon text-primary">
                                <use href="#custom-user-bold"></use>
                            </svg>
                            </div>
                            <div className="flex-grow-1 ms-3">
                            <span className="float-end text-sm text-muted">12 hour ago</span>
                            <h5 className="text-body mb-2">Challenge invitation</h5>
                            <p className="mb-2"><span className="text-dark">Jonny aber</span> invites to join the challenge</p>
                            <button className="btn btn-sm btn-outline-secondary me-2">Decline</button>
                            <button className="btn btn-sm btn-primary">Accept</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                            <svg className="pc-icon text-primary">
                                <use href="#custom-security-safe"></use>
                            </svg>
                            </div>
                            <div className="flex-grow-1 ms-3">
                            <span className="float-end text-sm text-muted">5 hour ago</span>
                            <h5 className="text-body mb-2">Security</h5>
                            <p className="mb-0"
                                >Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                type and scrambled it to make a type</p
                            >
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="text-center py-2">
                    <a href="#!" className="link-danger">Clear all Notifications</a>
                    </div>
                </div>
                </li> */}
                <li className="dropdown pc-h-item header-user-profile">
                <a
                    className="pc-head-link dropdown-toggle arrow-none me-0"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="false"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                >
                    <img src="../assets/images/user/avatar-2.jpg" alt="user-image" className="user-avtar" />
                </a>
                <div className="dropdown-menu dropdown-user-profile dropdown-menu-end pc-h-dropdown">
                    <div className="dropdown-header d-flex align-items-center justify-content-between">
                    <h5 className="m-0">Profile</h5>
                    </div>
                    <div className="dropdown-body">
                    <div className="profile-notification-scroll position-relative" style={{maxHeight: "calc(100vh - 225px)" }}>
                        <div className="d-flex mb-1">
                        <div className="flex-shrink-0">
                            <img src="../assets/images/user/avatar-2.jpg" alt="user-image" className="user-avtar wid-35" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                            <h6 className="mb-1" id="curr_user_name"></h6>
                            <span id="curr_user_email"></span>
                        </div>
                        </div>
                        <hr className="border-secondary border-opacity-50" />
                        <div className="card">
                        <div className="card-body py-3">
                            <div className="d-flex align-items-center justify-content-between">
                            <h5 className="mb-0 d-inline-flex align-items-center"
                                ><svg className="pc-icon text-muted me-2">
                                <use href="#custom-notification-outline"></use></svg
                                >Notification</h5
                            >
                            <div className="form-check form-switch form-check-reverse m-0">
                                <input className="form-check-input f-18" type="checkbox" role="switch" />
                            </div>
                            </div>
                        </div>
                        </div>
                        <p className="text-span">Manage</p>
                        <a href="#" className="dropdown-item">
                        <span>
                            <svg className="pc-icon text-muted me-2">
                            <use href="#custom-setting-outline"></use>
                            </svg>
                            <span>Settings</span>
                        </span>
                        </a>
                        <a href="#" className="dropdown-item">
                        <span>
                            <svg className="pc-icon text-muted me-2">
                            <use href="#custom-lock-outline"></use>
                            </svg>
                            <span>Change Password</span>
                        </span>
                        </a>
                        <hr className="border-secondary border-opacity-50" />
                        <div className="d-grid mb-3">
                        <button className="btn btn-primary" onClick={handleLogout}>
                            <svg className="pc-icon me-2">
                            <use href="#"></use></svg>Logout
                        </button>
                        </div>
                        <div
                        className="card border-0 shadow-none drp-upgrade-card mb-0"
                        style={{backgroundImage: "url(../assets/images/layout/img-profile-card.jpg)"}}
                        >
                        </div>
                    </div>
                    </div>
                </div>
                </li>
            </ul>
            </div>
            </div>
        </header>
    )
}

export default Header