import { useEffect, useState } from "react"
import axios from "axios"
const Content = () => {
    const [products,setProducts] = useState([])
    
    const handleSideBar = () => {
        let side = document.querySelector(".pc-sidebar")
        side.classList.remove("mob-sidebar-active")
    }

    useEffect(() => {
        async function fetchProducts() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/products`)
                setProducts(response.data)
            } catch (error) {
              console.error('Error fetching Products:', error);
            }
        }
      
        fetchProducts();
    
    },[])

    return (
        <div className="pc-container" onClick={handleSideBar}>
            <div className="pc-content">

                <div className="page-header">
                    <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                        <ul className="breadcrumb mb-3">
                            <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                            <li className="breadcrumb-item" aria-current="page">Products </li>
                        </ul>
                        </div>
                        <div className="col-md-12">
                        <div className="page-header-title">
                            <h2 className="mb-0">Products </h2>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="tab-content" id="pills-tabContent">
                    
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                        <div className="row justify-content-center align-items-center">
                            {products && products.map(product => (
                                <div className="col-md-6 col-lg-4">
                                    <div className={`card price-card ${product.name === "Platinum Tier Web Application" ? "price-popular" : product.name === "Platinum Tier Subscription" ? "price-popular" : ""}`}>
                                        <div className="card-body">
                                            <div className="price-head">
                                                <h5 className="mb-0">{product.name}</h5>
                                                <p className="text-muted">{product.subscription ? "01" : "02"} Services</p>
                                                <div className="price-price mt-4">{product._id == '6568dad8733009e97759e856' ? 'Contact Sales' : '$' + product.priceInCents / 100}<span className="text-muted"> {product.subscription ? "Monthly" : "One Time Payment"}</span></div>
                                                <div className="d-grid"><a className={`btn mt-4 ${product.name === "Platinum Tier Web Application" ? "btn-primary" : product.name === "Platinum Tier Subscription" ? "btn-primary" : "btn-outline-secondary"}`} href="#">Buy Now</a></div>
                                            </div>
                                            {/* <ul className="list-group list-group-flush product-list">
                                                <li className="list-group-item enable">One End Product</li>
                                                <li className="list-group-item enable">No attribution required</li>
                                                <li className="list-group-item enable">TypeScript</li>
                                                <li className="list-group-item">Figma Design Resources</li>
                                                <li className="list-group-item">Create Multiple Products</li>
                                                <li className="list-group-item">Create a SaaS Project</li>
                                                <li className="list-group-item">Resale Product</li>
                                            </ul> */}
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Content