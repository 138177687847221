import Tasks from "./Tasks"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import CompletionStatus from "./CompletionStatus"
import SubmitTaskInfo from "./SubmitTaskInfo"
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react"
import axios from "axios"
import TasksChart from "./TasksChart"

const SingleProject = () => {
    const { pid } = useParams();
    const [tasks, setTasks] = useState([]);
    const [project,setProject] = useState()

    const handleSideBar = () => {
        let side = document.querySelector(".pc-sidebar")
        side.classList.remove("mob-sidebar-active")
    }
    

    useEffect(() => {
      // Create a cancellation token source for axios
      const cancelTokenSource = axios.CancelToken.source();
  
      async function fetchTasks() {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/tasks/get/${pid}`, {
            cancelToken: cancelTokenSource.token, // Set the cancel token
          });
  
          setTasks(response.data);
        } catch (error) {
          if (axios.isCancel(error)) {
            // Request was canceled (component unmounted), ignore errors
            return;
          }
  
          // Handle other errors
          console.error('Error fetching tasks:', error);
          setTasks([]);
        }
      }

      async function fetchProject(){
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/projects/sn/${pid}`)
            setProject(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    fetchProject()
  
      fetchTasks();
  
      // Cleanup function
      return () => {
        // Cancel the axios request when the component is unmounted
        cancelTokenSource.cancel('Component is unmounted');
      };
    }, [pid]); // Include dependencies in the dependency array if needed

    return (
        <>
            <SideMenu/>
            <Header/>
            <div className="pc-container" onClick={handleSideBar}>
                <div className="pc-content">
                    <div className="row">
                        <Tasks tasks={tasks} project={project} />
                        <TasksChart tasks={tasks} />
                        <CompletionStatus tasks={tasks} project={project} pid={pid} />
                        <SubmitTaskInfo tasks={tasks} />
                    </div>
                </div>
            </div> 
            <Footer/>
        </>
    )
}

export default SingleProject