import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Projects from './components/Projects/Projects';
import SingleProject from './components/SingleProject/SingleProject';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import PaymentPortal from './components/PaymentPortal/PaymentPortal';
import Construction from './components/Construction/Construction'
import Requests from './components/Requests/Requests';
import Products from './components/Products/Products';
import Register from './components/Login/Register/Register'
import Contact from './components/Contact/Contact';
// import Chat from './components/Chat/Chat';

const App = () => {

    return (
        <Router>
            <Routes>
                <Route path='/' element={<Login/>} />
                <Route path='/register' element={<Register/>} />
                <Route
                    path='/constr'
                    element={<ProtectedRoute child={<Construction />} />}
                />
                <Route
                    path="/dashboard"
                    element={<ProtectedRoute child={<Dashboard />} />}
                />
                <Route
                    path="/projects"
                    element={<ProtectedRoute child={<Projects />} />}
                />
                <Route
                    path="/projects/:pid"
                    element={<ProtectedRoute child={<SingleProject />} />}
                />
                <Route
                    path="/paymentportal"
                    element={<ProtectedRoute child={<PaymentPortal />} />}
                />
                <Route
                    path="/requests"
                    element={<ProtectedRoute child={<Requests />} />}
                />
                <Route
                    path="/products"
                    element={<ProtectedRoute child={<Products />} />}
                />
                <Route
                    path='/contact'
                    element={<ProtectedRoute child={<Contact />} />}
                />
                {/* <Route
                    path="/chat"
                    element={<ProtectedRoute child={<Chat />} />}
                /> */}
            </Routes>
        </Router>
    );
};

export default App;