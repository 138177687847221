import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const Element = ({ child }) => {
  return (
  <div>{child}</div>
  );
};

const ProtectedRoute = ({ child }) => {
  const { user, loading } = useAuth();
  useEffect(() => {
  }, [user,loading]);

  return loading ? null : user ? <Element child={child} /> : <Navigate to="/" />;
};

export default ProtectedRoute;
