import SignIn from "./SignIn/SignIn"
import { useState } from "react"
import Alert from "../Alert/Alert"

const Login = () => {
    const [message,setMessage] = useState({display: false, error: false, text: "" })

    return (
        <>
            {message.display && <Alert type={message.error ? 'alert-danger' : 'alert-success'} text={message.text} />}
            <SignIn message={message} setMessage={setMessage} />
        </>
    )
}

export default Login