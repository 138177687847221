const Tasks = ({tasks, project}) => {

    return (
        <div className="col-xl-6 col-md-12">
            <div className="card table-card" style={{ overflowY: 'auto', maxHeight: '600px' }}>
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5>{project && project.title}</h5>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover table-borderless mb-0">
                            <thead>
                                <tr>
                                    <th>Task</th>
                                    <th>Agent</th>
                                    <th>Completion Date</th>
                                    <th className="text-end">Status</th>
                                </tr>
                            </thead>
                            <tbody id="projects-table">
                                {tasks.length > 0 && tasks.map((task) => (
                                    <tr key={task._id}>
                                        <td>{task.title}</td>
                                        <td>{`${task.agent.first_name} ${task.agent.last_name}`}</td>
                                        <td>{task.completed_date ? task.completed_date : "Incomplete"}</td>
                                        <td className="text-end"><label className={`badge ${task.status === "Complete" ? "bg-light-success" : task.status === "In Progress" ? "bg-light-primary" : task.status === "Pending" ? "bg-light-warning" : "bg-light-danger"}`}>{task.status}</label></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tasks