import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
const TasksChart = ({tasks}) => {
    // const [tasksByStatus, setTasksByStatus] = useState([])
    const [data,setData] = useState([])
    useEffect(() => {
        // Initialize an object to store the counts for each status
        const statusCounts = {};

        // Iterate over the tasks and count occurrences of each status
        tasks.forEach(task => {
            const status = task.status;

            // If the status key doesn't exist in the statusCounts object, initialize it to 0
            statusCounts[status] = (statusCounts[status] || 0) + 1;
            });

            // Remove status counts with a value of 0
            Object.keys(statusCounts).forEach(status => {
            if (statusCounts[status] === 0) {
                delete statusCounts[status];
            }
        });

        // Create an array of objects with the desired structure
        const resultArray = Object.keys(statusCounts).map(status => ({
            value: statusCounts[status],
            name: status,
          }));

        setData(resultArray)
    },[tasks])
    const option = {
        tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: 'Tasks By',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 16,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: data
            }
          ]
    }
    return (
        <div className="col-lg-6">
            <div className="card">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0">Tasks By Status</h5>
                    </div>
                    <ReactECharts
                        option={option}
                        style={{ height: '300px' }}
                        opts={{renderer: 'svg'}} // use svg to render the chart.
                    />
                    <div className="row g-3 mt-3">
                        {data && data.map(d => (
                            <div className="col-sm-6" key={d.name}>
                                <div className="bg-body p-3 rounded">
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="flex-shrink-0">
                                            <span className="p-1 d-block bg-primary rounded-circle">
                                                <span className="visually-hidden">{d.name}</span>
                                            </span>
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <p className="mb-0">{d.name}</p>
                                        </div>
                                    </div>
                                    <h6 className="mb-0">{d.value}</h6>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TasksChart