import { useEffect, useState } from "react"
import axios from "axios"
import { jwtDecode } from "jwt-decode";
import Alert from "../Alert/Alert";

const Content = () => {
    const [projects, setProjects] = useState([])
    const [user,setUser] = useState({})
    const [newProject, setNewProject] = useState({ email: "", name: "", completeBy: "", title: "", type: "", desc: "" })
    const [message,setMessage] = useState({display: false, error: false, text: "" })
    const handleSideBar = () => {
        let side = document.querySelector(".pc-sidebar")
        side.classList.remove("mob-sidebar-active")
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const type = newProject.type === "" ? "Web Application" : newProject.type
        
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/contact/req-new-project`, {email: user.email, name: user.name, completeBy: newProject.completeBy, title: newProject.title, type: type, desc: newProject.desc});
      
            if (response.status === 200) showMessage(false, 'Thank you for requesting a new project, a member of our staff will reach out shortly.')
            else showMessage(true, 'Something went wrong, please try again or contact support@g-2technologies.com')
  
        } catch (error) {
            showMessage(true, 'Something went wrong, please try again or contact support@g-2technologies.com');
        }
        
    }

    const showMessage = (error, text) => {
        setMessage({ display: true, error, text });
        setTimeout(() => {
          setMessage( (prevMessage) => ({ ...prevMessage, display: false }));
        }, 5000);
    }


    useEffect(() => {
        // Decode the JWT token and set the user state
        const decodedUser = jwtDecode(localStorage?.token);
        setUser(decodedUser);
    
        // Use a cancellation token source for axios
        const cancelTokenSource = axios.CancelToken.source();
    
        // Fetch projects and set the state
        async function fetchProjects() {
          try {
            const { role } = decodedUser;
            let response;
    
            if (role === "admin") {
              response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/projects/getadmin/${localStorage.getItem("id")}`, {
                cancelToken: cancelTokenSource.token,
              });
            } else {
              response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/projects/get/${localStorage.getItem("id")}`, {
                cancelToken: cancelTokenSource.token,
              });
            }
    
            setProjects(response.data);
          } catch (error) {
            if (axios.isCancel(error)) {
              // Request was canceled (component unmounted), ignore errors
              return;
            }
    
            console.error('Error fetching projects:', error);
            setProjects([]); // Set projects to an empty array on error
          }
        }
    
        fetchProjects();
    
        // Cleanup function
        return () => {
          // Cancel the axios request when the component is unmounted
          cancelTokenSource.cancel('Component is unmounted');
        };
      }, []); // Empty dependency array means this effect runs once when the component mounts

    return (
        <div className="pc-container" onClick={handleSideBar}>
            {message.display && <Alert type={message.error ? 'alert-danger' : 'alert-success'} text={message.text} />}
            <div className="pc-content">

                {/* Recent Projects Table */}
                <div className="col-xl-6 col-md-12">
                    <div className="card table-card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <h5>Recent Projects</h5>
                            <a href="#" className="link-primary">View all</a>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Agent</th>
                                            <th>Completion Date</th>
                                            <th className="text-end">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody id="projects-table">
                                        {projects.length && projects.map((project) => (
                                            <tr key={project._id}>
                                                <td><a href={`/projects/${project._id}`}>{project.title}</a></td>
                                                <td>{`${project.agent.first_name} ${project.agent.last_name}`}</td>
                                                <td></td>
                                                <td className="text-end"><label className={`badge  ${project.status === 'Development' ? 'bg-light-primary' : 'bg-light-warning'}`}>{project.status}</label></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            {/* Submit new proj form */}
                <div className="card">
                    <div className="card-header">
                    <h5>Request New Project</h5>
                    </div>
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="exampleInputEmail1">Email address</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="usersemail@email.com" disabled/>
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="exampleInputEmail1">Name</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="User Name" disabled/>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="exampleInputEmail1">Desired Completion Date</label>
                                <input type="date" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => setNewProject({...newProject, completeBy: e.target.value})} />
                            </div>
                            <button type="submit" className="btn btn-primary mb-4">Submit</button>
                        </form>
                        </div>
                        <div className="col-md-6">
                        <form>
                            <div className="form-group">
                            <label className="form-label">Title</label>
                            <input type="text" className="form-control" placeholder="Project Title" onChange={(e) => setNewProject({...newProject, title: e.target.value})} />
                            </div>
                            <div className="form-group">
                            <label className="form-label" htmlFor="exampleFormControlSelect1">Project Type</label>
                            <select className="form-select" id="exampleFormControlSelect1" onChange={(e) => setNewProject({...newProject, type: e.target.value})} >
                                <option value={"Web Application"}>Web Application</option>
                                <option value={"API Integration"} >API Integration</option>
                                <option value={"AI Solution"}>AI Solution</option>
                                <option value={"Enhancement"}>Enhancement</option>
                                <option value={"Other"}>Other</option>
                            </select>
                            </div>
                            <div className="form-group">
                            <label className="form-label" htmlFor="exampleFormControlTextarea1">Project Description</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setNewProject({...newProject, desc: e.target.value})}></textarea>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Content