import { useAuth } from "../../contexts/AuthContext";
const NavUser = ({ user }) => {
    const { logout } = useAuth(); // Import the login function from your AuthContex
    const handleLogout = () => {
        logout()
    }
    return (
        <div className="card pc-user-card">
            <div className="card-body">
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                        <img src="../assets/images/user/avatar-1.jpg" alt="user" className="user-avtar wid-45 rounded-circle" />
                    </div>
                    <div className="flex-grow-1 ms-3 me-2">
                        <h6 className="mb-0" id="curr_user">{user ? user.name : "Anon"}</h6>
                        <small id="user-role">{user && user.role}</small>
                    </div>
                    <a className="btn btn-icon btn-link-secondary avtar" data-bs-toggle="collapse" href="#pc_sidebar_userlink">
                        <svg className="pc-icon">
                            <use href="#custom-sort-outline"></use>
                        </svg>
                    </a>
                </div>
                <div className="collapse pc-user-links" id="pc_sidebar_userlink">
                    <div className="pt-3">
                        <a href="#!">
                            <i className="ti ti-user"></i>
                            <span>My Account</span>
                        </a>
                        <a href="#!">
                            <i className="ti ti-settings"></i>
                            <span>Settings</span>
                        </a>
                        <a href="#" onClick={handleLogout}>
                            <i className="ti ti-power"></i>
                            <span>Logout</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavUser