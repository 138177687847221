import axios from "axios";
import { useEffect, useState } from "react"

const SubmitTaskInfo = ({tasks}) => {
    const [successMessage, setSuccessMessage] = useState("");
    const [taskInfo, setTaskInfo] = useState({ taskId: "", files: [], notes: ""})

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create a FormData object
        const formData = new FormData();
        taskInfo.files.forEach((file, index) => {
            formData.append('files', file); // Ensure 'files' is the field name
        });
        formData.append('notes', taskInfo.notes); // Append the notes

        console.log(taskInfo)
        console.log(formData)
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER_BASE}/tasks/update-wfls/${taskInfo.taskId}`, formData, {
            // const response = await axios.put(`${process.env.REACT_APP_SERVER_BASE}/tasks/sendmessage`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Set the content type to multipart/form-data
                }
            });
            console.log(response.data); // Handle the response data
            // Set the success message
            setSuccessMessage("Task updated successfully!");
            // Clear the form fields
            setTaskInfo({ taskId: taskInfo.taskId, files: [], notes: "" });
            e.target.reset(); // Reset the form fields

            // Clear the success message after a few seconds
            setTimeout(() => {
                setSuccessMessage("");
            }, 3000);
        } catch (error) {
            console.error("There was an error submitting the form!", error);
        }
    };

    const handleFileChange = (e) => {
        setTaskInfo({
            ...taskInfo,
            files: Array.from(e.target.files) // Capture the uploaded files
        });
    };

    const handleTaskChange = (e) => {
        setTaskInfo({
            ...taskInfo,
            taskId: e.target.value // Capture the selected task ID
        });
    };

    useEffect(() => {
        if (tasks && tasks.length > 0) {
            const firstTask = tasks.find(task => task.status !== "Complete");
            if (firstTask) {
                setTaskInfo(prev => ({ ...prev, taskId: firstTask._id }));
            }
        }
    }, [tasks]);

    return (
        <div className="col-xl-6 col-md-12">
        <div className="card">
            <div className="card-header">
                <h5>Submit Task Information</h5>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="exampleFormControlSelect1">Task</label>
                                <select className="form-select" id="exampleFormControlSelect1" value={taskInfo.taskId} onChange={handleTaskChange}>
                                    {tasks && tasks.map(task => (
                                        task.status !== "Complete" && <option key={task._id} id={task._id} value={task._id}>{task.title}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Files</label>
                                <input type="file" className="form-control" multiple onChange={handleFileChange} />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="exampleFormControlTextarea1">Additional Notes</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setTaskInfo({...taskInfo, notes: e.target.value})}></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary mb-4">Submit</button>
                        </form>
                        {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default SubmitTaskInfo