import axios from "axios";
import { useEffect, useState } from "react"

const CompletionStatus = ({tasks, project, pid}) => {

    const [completion, setCompletion] = useState(0);
    const [formData,setFormData] = useState({title: "", project: pid})

    const handleTaskSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/tasks/create`, formData)
            window.location.reload()
        } catch (error) {
            console.log("Error: ", error)
            window.location.reload()
        }
    }

    useEffect(() => {
        const len = tasks.length;

        if (len > 0) {
            const comp = tasks.filter((t) => t.status === "Complete").length;
            setCompletion(Math.round((comp / len) * 100));
        } else {
            setCompletion(0);
        }
    }, [tasks]);

    return (
        <>
            <div className="col-lg-3">
                <div className="card">
                    <div className="card-header">
                        <h5 className="mb-0">Project - {project && project.title}</h5>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleTaskSubmit}>
                            <div className="mb-4">
                                <p className="mb-2">Release v1.0<span className="float-end">{completion}%</span></p>
                                <div className="progress progress-primary" style={{height: "8px"}}>
                                    <div className="progress-bar" style={{width: `${completion}%`}}></div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="form-group">
                                    <label className="form-label">Title</label>
                                    <input type="text" className="form-control" onChange={(e) => setFormData({...formData, title: e.target.value})} />
                                </div>
                            </div>
                            <div className="d-grid mt-3">
                                <button className="btn btn-primary d-flex align-items-center justify-content-center" type="submit"><i className="ti ti-plus"></i> Add task</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompletionStatus