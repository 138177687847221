// AuthContext.js
import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      const userData = JSON.parse(storedUser);
      const { token } = userData;

      try {
        const decodedToken = jwtDecode(token);
        // Check if the token is expired
        const isExpired = decodedToken.exp < Date.now() / 1000;

        if (!isExpired) {
          setUser(userData);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }

    setLoading(false);
  }, []);

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData)); // Use 'user' here
    localStorage.setItem('token', userData.token);
    localStorage.setItem('id', userData.id)
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('id');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
