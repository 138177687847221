import { useEffect, useState } from "react"
import axios from "axios"
import { jwtDecode } from "jwt-decode";

const Content = () => {
    const [requests, setRequests] = useState([])
    const [projects, setProjects] = useState([])
    const [formData,setFormData] = useState({ type: "", user: localStorage?.id && localStorage.id, project: "", description: ""})
    const [user,setUser] = useState()
    
    const handleSideBar = () => {
        let side = document.querySelector(".pc-sidebar")
        side.classList.remove("mob-sidebar-active")
    }

    const handleNewRequest = async (e) => {
        e.preventDefault()
        try {
            const req = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/requests/new`,formData)
            if(req.status === 200) {
                window.location.reload()
            } else {
                console.log("There was an Error...")
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // Decode the JWT token and set the user state
        const decodedUser = jwtDecode(localStorage?.token);
        setUser(decodedUser);
    
        // Use a cancellation token source for axios
        const cancelTokenSource = axios.CancelToken.source();
    
        // Fetch projects and set the state
        async function fetchProjects() {
          try {
            const { role } = decodedUser;
            let response;
    
            if (role === "admin") {
              response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/projects/getadmin/${localStorage.getItem("id")}`, {
                cancelToken: cancelTokenSource.token,
              });
            } else {
              response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/projects/get/${localStorage.getItem("id")}`, {
                cancelToken: cancelTokenSource.token,
              });
            }
    
            setProjects(response.data);
          } catch (error) {
            if (axios.isCancel(error)) {
              // Request was canceled (component unmounted), ignore errors
              return;
            }
    
            console.error('Error fetching projects:', error);
            setProjects([]); // Set projects to an empty array on error
          }
        }

        // Fetch projects and set the state
        async function fetchRequests() {
            try {
                const { role } = decodedUser;
                let response;

                if (role === "admin") {
                    response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/requests/getadmin/${localStorage.getItem("id")}`, {
                        cancelToken: cancelTokenSource.token,
                    });
                } else {
                    response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/requests/getuser/${localStorage.getItem("id")}`, {
                        cancelToken: cancelTokenSource.token,
                    });
                }

                setRequests(response.data);
            } catch (error) {
                if (axios.isCancel(error)) {
                    // Request was canceled (component unmounted), ignore errors
                    return;
                }

                console.error('Error fetching requests:', error);
                setProjects([]); // Set projects to an empty array on error
            }
        }
    
        fetchProjects();
        fetchRequests()
    
        // Cleanup function
        return () => {
          // Cancel the axios request when the component is unmounted
          cancelTokenSource.cancel('Component is unmounted');
        };
      }, []); // Empty dependency array means this effect runs once when the component mounts

    return (
        <div className="pc-container" onClick={handleSideBar}>

            <div className="pc-content">

                {/* Recent Projects Table */}
                <div className="col-xl-6 col-md-12">
                    <div className="card table-card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <h5>My Requests</h5>
                            <a href="#" className="link-primary">View all</a>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Project</th>
                                            <th>Agent</th>
                                            <th>Completion Date</th>
                                            <th className="text-end">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody id="projects-table">
                                        {requests.length ? requests.map((request) => (
                                            <tr key={request._id}>
                                                <td><a href={`#`}>{request.type}</a></td>
                                                <td>{request.project}</td>
                                                <td>{`${request.agent.first_name} ${request.agent.last_name}`}</td>
                                                <td></td>
                                                <td className="text-end"><label className={`badge  ${request.status === 'Development' ? 'bg-light-primary' : 'bg-light-warning'}`}>{request.status}</label></td>
                                            </tr>
                                        )) 
                                        :
                                        <tr>
                                            <td>No Open Requests</td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            {/* Submit new req form */}
                <div className="card">
                    <div className="card-header">
                    <h5>Submit New Request</h5>
                    </div>
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                        <form onSubmit={handleNewRequest}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="exampleInputEmail1">Email address</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={user && user.email} disabled/>
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="exampleInputEmail1">Name</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={user && user.name} disabled/>
                            </div>
                            <button type="submit" className="btn btn-primary mb-4">Submit</button>
                        </form>
                        </div>
                        <div className="col-md-6">
                        <form>
                            <div className="form-group">
                                <label className="form-label" htmlFor="exampleFormControlSelect1">Project</label>
                                <select className="form-select" id="exampleFormControlSelect1" onChange={(e) => setFormData({...formData, project: e.target.value}) }>
                                    {projects && projects.map(project => (
                                        <option key={project._id} value={project._id}>{project.title}</option>
                                    ))}
                                    <option value="N/A">N/A</option>
                                </select>
                                </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="exampleFormControlSelect1">Request Type</label>
                                <select className="form-select" id="exampleFormControlSelect1" onChange={(e) => setFormData({...formData, type: e.target.value})}>
                                    <option value="Incident (Something's Broken)">Incident (Something's Broken)</option>
                                    <option value="Change">Change</option>
                                    <option value="Additional Feature">Additional Feature</option>
                                    <option value="CMS - Incident">CMS - Incident</option>
                                    <option value="CMS - Change">CMS - Change</option>
                                    <option value="API - Incident">API - Incident</option>
                                    <option value="CMS - Change">CMS - Change</option>
                                    <option value="Report A Bug">Report A Bug</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="form-group">
                            <label className="form-label" htmlFor="exampleFormControlTextarea1">Request Description</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setFormData({...formData, description: e.target.value})}></textarea>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Content