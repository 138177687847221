import axios from "axios"
import { useState } from "react"
import Alert from "../../Alert/Alert"

const Register = () => {
  const [message,setMessage] = useState({display: false, error: false, text: "" })
    const [formData,setFormData] = useState({ first_name: "" }, { last_name: "" }, { email: "" }, { password: "" })

    const showMessage = (error, text) => {
        setMessage({ display: true, error, text });
        setTimeout(() => {
          setMessage( (prevMessage) => ({ ...prevMessage, display: false }));
        }, 5000);
      }

      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/users/new`, formData);
    
          if (response.status === 200) showMessage(false, 'You will receive an email to complete your registration. (Email may be in junk)')
          else showMessage(true, 'Something went wrong, please try again or contact support@g-2technologies.com')

        } catch (error) {
          showMessage(true, 'Something went wrong, please try again or contact support@g-2technologies.com');
        }
    
        e.target.reset();
      }

    return (
      <>
      {message.display && <Alert type={message.error ? 'alert-danger' : 'alert-success'} text={message.text} />}
      <div className="auth-main">
      <div className="auth-wrapper v1">
        <div className="auth-form">
          <div className="card my-5">
            <div className="card-body">
              <div className="text-center">
                <a href="/"><img src="images/LOGO.png" alt="img" style={{width: "60px", marginBottom: "1em"}}/></a>
              </div>
              <h4 className="text-center f-w-500 mb-3">Sign up with your email.</h4>
              <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group mb-3">
                    <input type="text" className="form-control" placeholder="First Name" onChange={(e) => setFormData({...formData, first_name: e.target.value})} />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group mb-3">
                    <input type="text" className="form-control" placeholder="Last Name" onChange={(e) => setFormData({...formData, last_name: e.target.value})} />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3">
                <input type="email" className="form-control" placeholder="Email Address" onChange={(e) => setFormData({...formData, email: e.target.value})} />
              </div>
              <div className="form-group mb-3">
                <input type="password" className="form-control" placeholder="Password" onChange={(e) => setFormData({...formData, password: e.target.value})} />
              </div>
              <div className="form-group mb-3">
                <input type="password" className="form-control" placeholder="Confirm Password"/>
              </div>
              <div className="d-flex mt-1 justify-content-between">
                <div className="form-check">
                  <input className="form-check-input input-primary" type="checkbox" id="customCheckc1" defaultChecked />
                  <label className="form-check-label text-muted" htmlFor="customCheckc1">I agree to all the Terms & Condition</label>
                </div>
              </div>
              <div className="d-grid mt-4">
                <button type="submit" className="btn btn-primary">Register</button>
              </div>
              <div className="d-flex justify-content-between align-items-end mt-4">
                <h6 className="f-w-500 mb-0">Don't have an Account?</h6>
                <a href="/register" className="link-primary">Create Account</a>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
    )
}

export default Register