import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"

const Construction = () => {
    const handleSideBar = () => {
        let side = document.querySelector(".pc-sidebar")
        side.classList.remove("mob-sidebar-active")
    }
    return (
        <>
            {/* Pre-loader */}
            <div className="loader-bg">
                <div className="loader-track">
                    <div className="loader-fill"></div>
                </div>
            </div>
            <SideMenu/>
            <Header/>
            <div className="pc-container" onClick={handleSideBar}>
                <div className="pc-content">
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", minHeight: "100vh", justifyContent: "center"}}>
                <div>
                    <img src="images/img-cunstruct-2.svg" alt="construction" />
                </div>
                <div>
                    <h2>Under Construction</h2>
                </div>
                <div>
                    <p className="text-muted">Please check out this link later. We are doing some maintenance on it right now.</p>
                </div>
                {/* <div>
                    <a className="btn btn-primary" style={{borderRadius: "14px"}} href="/dashboard">Back to home</a>
                </div> */}
            </div>
            </div>
            </div>
            <Footer/>
        </>
    )
}

export default Construction