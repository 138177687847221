import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import { useState } from "react"
import axios from "axios"
import Alert from "../Alert/Alert"

const Contact = () => {
    const [message,setMessage] = useState({display: false, error: false, text: "" })
    const [formData,setFormData] = useState({ first_name: "" }, { last_name: "" }, { email: "" }, { phone: "" }, {message: ""})
    
    const handleSideBar = () => {
        let side = document.querySelector(".pc-sidebar")
        side.classList.remove("mob-sidebar-active")
    }

    const showMessage = (error, text) => {
        setMessage({ display: true, error, text });
        setTimeout(() => {
          setMessage( (prevMessage) => ({ ...prevMessage, display: false }));
        }, 5000);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData)

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/contact/sendmessage`, formData);
      
            if (response.status === 200) showMessage(false, 'Thank you for contacting us, a member of our staff will reach out shortly.')
            else showMessage(true, 'Something went wrong, please try again or contact support@g-2technologies.com')
  
        } catch (error) {
            showMessage(true, 'Something went wrong, please try again or contact support@g-2technologies.com');
        }
      
        e.target.reset();
    }

    return (

        <>
            {/* Pre-loader */}
            <div className="loader-bg">
                <div className="loader-track">
                    <div className="loader-fill"></div>
                </div>
            </div>
            <SideMenu />
            <Header/>
            {/* [ Main Content ] start */}
            <div className="pc-container" onClick={handleSideBar}>
            {message.display && <Alert type={message.error ? 'alert-danger' : 'alert-success'} text={message.text} />}
                <section className="contact-hero" style={{backgroundImage: "url(../assets/images/landing/img-headerbg.jpg)"}}>
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-md-5">
                                <h2>Contact a member of our team</h2>
                                <p className="text-muted">Let us know how we can help you and a member of our staff will contact you shortly.</p>
                            </div>
                        </div>
                    </div>
                </section>
            <section className="contact-form bg-white">
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-12"><h2 className="sr-only">contact form</h2></div>
                    <div className="col-xxl-6 col-md-8 col-sm-10">
                    <form onSubmit={handleSubmit}>
                        <div className="row my-4">
                        <div className="col-sm-6">
                            <div className="form-group">
                            <label className="form-label">First name</label>
                            <input type="text" className="form-control" placeholder="First name" onChange={(e) => setFormData({...formData, first_name: e.target.value})} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                            <label className="form-label">Last name</label>
                            <input type="text" className="form-control" placeholder="Last name" onChange={(e) => setFormData({...formData, last_name: e.target.value})} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" placeholder="Email" onChange={(e) => setFormData({...formData, email: e.target.value})} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                            <label className="form-label">Phone Number</label>
                            <input type="text" className="form-control" placeholder="Phone Number" onChange={(e) => setFormData({...formData, phone: e.target.value})} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">Message</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setFormData({...formData, message: e.target.value})}></textarea>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mt-4 d-grid">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            </section>
            </div>
            {/* [ Main Content ] end */}
            <Footer />
        </>
    )
}

export default Contact