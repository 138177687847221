import { useEffect, useState } from "react";
import axios from "axios"
import { jwtDecode } from "jwt-decode";

const Content = () => {
    const [requests, setRequests] = useState([])
    const [projects, setProjects] = useState([])
    const [user,setUser] = useState()

    useEffect(() => {
        // Decode the JWT token and set the user state
        const decodedUser = jwtDecode(localStorage?.token);
        setUser(decodedUser);
    
        // Use a cancellation token source for axios
        const cancelTokenSource = axios.CancelToken.source();
    
        // Fetch projects and set the state
        async function fetchProjects() {
          try {
            const { role } = decodedUser;
            let response;
    
            if (role === "admin") {
              response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/projects/getadmin/${localStorage.getItem("id")}`, {
                cancelToken: cancelTokenSource.token,
              });
            } else {
              response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/projects/get/${localStorage.getItem("id")}`, {
                cancelToken: cancelTokenSource.token,
              });
            }
    
            setProjects(response.data);
          } catch (error) {
            if (axios.isCancel(error)) {
              // Request was canceled (component unmounted), ignore errors
              return;
            }
    
            console.error('Error fetching projects:', error);
            setProjects([]); // Set projects to an empty array on error
          }
        }

        // Fetch projects and set the state
        async function fetchRequests() {
            try {
                const { role } = decodedUser;
                let response;

                if (role === "admin") {
                    response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/requests/getadmin/${localStorage.getItem("id")}`, {
                        cancelToken: cancelTokenSource.token,
                    });
                } else {
                    response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/requests/getuser/${localStorage.getItem("id")}`, {
                        cancelToken: cancelTokenSource.token,
                    });
                }

                setRequests(response.data);
            } catch (error) {
                if (axios.isCancel(error)) {
                    // Request was canceled (component unmounted), ignore errors
                    return;
                }

                console.error('Error fetching requests:', error);
                setProjects([]); // Set projects to an empty array on error
            }
        }
    
        fetchProjects();
        fetchRequests()
    
        // Cleanup function
        return () => {
          // Cancel the axios request when the component is unmounted
          cancelTokenSource.cancel('Component is unmounted');
        };
      }, []); // Empty dependency array means this effect runs once when the component mounts

    return (
        <div className="pc-content">

            {/* Recent Requests Table */}
            <div className="col-xl-6 col-md-12">
                <div className="card table-card">
                    <div className="card-header d-flex align-items-center justify-content-between">
                        <h5>My Requests</h5>
                        <a href="#" className="link-primary">View all</a>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Project</th>
                                        <th>Agent</th>
                                        <th>Completion Date</th>
                                        <th className="text-end">Status</th>
                                    </tr>
                                </thead>
                                <tbody id="projects-table">
                                    {requests.length ? requests.map((request) => (
                                        <tr key={request._id}>
                                            <td><a href={`#`}>{request.type}</a></td>
                                            <td>{request.project}</td>
                                            <td>{`${request.agent.first_name} ${request.agent.last_name}`}</td>
                                            <td></td>
                                            <td className="text-end"><label className={`badge  ${request.status === 'Development' ? 'bg-light-primary' : 'bg-light-warning'}`}>{request.status}</label></td>
                                        </tr>
                                    ))
                                        :
                                        <tr>
                                            <td>No Open Requests</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Recent Projects Table */}
            <div className="col-xl-6 col-md-12">
                <div className="card table-card">
                    <div className="card-header d-flex align-items-center justify-content-between">
                        <h5>Recent Projects</h5>
                        <a href="#" className="link-primary">View all</a>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Agent</th>
                                        <th>Completion Date</th>
                                        <th className="text-end">Status</th>
                                    </tr>
                                </thead>
                                <tbody id="projects-table">
                                    {projects.length && projects.map((project) => (
                                        <tr key={project._id}>
                                            <td><a href={`/projects/${project._id}`}>{project.title}</a></td>
                                            <td>{`${project.agent.first_name} ${project.agent.last_name}`}</td>
                                            <td></td>
                                            <td className="text-end"><label className={`badge  ${project.status === 'Development' ? 'bg-light-primary' : 'bg-light-warning'}`}>{project.status}</label></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Content